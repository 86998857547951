@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body{
    background-color: white;
    /* color:#00000080 */
}
.banner_pattern{
    background: url('../assets//image\ \(35\).svg');
    background-position: center;
    /* background-size: cover; */
}
.banner_backdrop{
    backdrop-filter: brightness(0.09);
}
.banner_background_hero{
    background: url('../assets/blue-yellow-floral-fabric-background\ 1.png');
    background-size: cover;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.banner_map{
background: url('../assets/map.png');
background-position: top;
/* background-size: cover; */
background-position-y: -80px;
background-repeat: no-repeat;
backdrop-filter: brightness(0.08);
}